var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-card",
                {
                  staticClass: "mgb20",
                  staticStyle: { height: "252px" },
                  attrs: { shadow: "hover" }
                },
                [
                  _c("div", { staticClass: "user-info" }, [
                    _c("img", {
                      staticClass: "user-avator",
                      attrs: {
                        src: require("../../assets/img/img.jpg"),
                        alt: ""
                      }
                    }),
                    _c("div", { staticClass: "user-info-cont" }, [
                      _c("div", { staticClass: "user-info-name" }, [
                        _vm._v(_vm._s(_vm.name))
                      ]),
                      _c("div", [_vm._v(_vm._s(_vm.role))])
                    ])
                  ]),
                  _c("div", { staticClass: "user-info-list" }, [
                    _vm._v(
                      "\n                    上次登录时间：\n                    "
                    ),
                    _c("span", [_vm._v("2019-11-01")])
                  ]),
                  _c("div", { staticClass: "user-info-list" }, [
                    _vm._v(
                      "\n                    上次登录地点：\n                    "
                    ),
                    _c("span", [_vm._v("东莞")])
                  ])
                ]
              ),
              _c(
                "el-card",
                {
                  staticStyle: { height: "252px" },
                  attrs: { shadow: "hover" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [_c("span", [_vm._v("语言详情")])]
                  ),
                  _vm._v("\n                Vue "),
                  _c("el-progress", {
                    attrs: { percentage: 71.3, color: "#42b983" }
                  }),
                  _vm._v("JavaScript\n                "),
                  _c("el-progress", {
                    attrs: { percentage: 24.1, color: "#f1e05a" }
                  }),
                  _vm._v("CSS "),
                  _c("el-progress", { attrs: { percentage: 13.7 } }),
                  _vm._v("HTML\n                "),
                  _c("el-progress", {
                    attrs: { percentage: 5.9, color: "#f56c6c" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 16 } },
            [
              _c(
                "el-row",
                { staticClass: "mgb20", attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-card",
                        {
                          attrs: {
                            shadow: "hover",
                            "body-style": { padding: "0px" }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "grid-content grid-con-1" },
                            [
                              _c("i", {
                                staticClass: "el-icon-lx-people grid-con-icon"
                              }),
                              _c("div", { staticClass: "grid-cont-right" }, [
                                _c("div", { staticClass: "grid-num" }, [
                                  _vm._v("1234")
                                ]),
                                _c("div", [_vm._v("用户访问量")])
                              ])
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-card",
                        {
                          attrs: {
                            shadow: "hover",
                            "body-style": { padding: "0px" }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "grid-content grid-con-2" },
                            [
                              _c("i", {
                                staticClass: "el-icon-lx-notice grid-con-icon"
                              }),
                              _c("div", { staticClass: "grid-cont-right" }, [
                                _c("div", { staticClass: "grid-num" }, [
                                  _vm._v("321")
                                ]),
                                _c("div", [_vm._v("系统消息")])
                              ])
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-card",
                        {
                          attrs: {
                            shadow: "hover",
                            "body-style": { padding: "0px" }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "grid-content grid-con-3" },
                            [
                              _c("i", {
                                staticClass: "el-icon-lx-goods grid-con-icon"
                              }),
                              _c("div", { staticClass: "grid-cont-right" }, [
                                _c("div", { staticClass: "grid-num" }, [
                                  _vm._v("5000")
                                ]),
                                _c("div", [_vm._v("数量")])
                              ])
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-card",
                {
                  staticStyle: { height: "403px" },
                  attrs: { shadow: "hover" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [
                      _c("span", [_vm._v("待办事项")]),
                      _c(
                        "el-button",
                        {
                          staticStyle: { float: "right", padding: "3px 0" },
                          attrs: { type: "text" }
                        },
                        [_vm._v("添加")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { "show-header": false, data: _vm.todoList }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { width: "40" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-checkbox", {
                                  model: {
                                    value: scope.row.status,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "status", $$v)
                                    },
                                    expression: "scope.row.status"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "todo-item",
                                    class: { "todo-item-del": scope.row.status }
                                  },
                                  [_vm._v(_vm._s(scope.row.title))]
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c(
                        "el-table-column",
                        { attrs: { width: "60" } },
                        [
                          [
                            _c("i", { staticClass: "el-icon-edit" }),
                            _c("i", { staticClass: "el-icon-delete" })
                          ]
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-card",
                { attrs: { shadow: "hover" } },
                [
                  _c("schart", {
                    ref: "bar",
                    staticClass: "schart",
                    attrs: { canvasId: "bar", options: _vm.options }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-card",
                { attrs: { shadow: "hover" } },
                [
                  _c("schart", {
                    ref: "line",
                    staticClass: "schart",
                    attrs: { canvasId: "line", options: _vm.options2 }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }