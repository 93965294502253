import { render, staticRenderFns } from "./Dashboard.vue?vue&type=template&id=4055c304&scoped=true&"
import script from "./Dashboard.vue?vue&type=script&lang=js&"
export * from "./Dashboard.vue?vue&type=script&lang=js&"
import style0 from "./Dashboard.vue?vue&type=style&index=0&id=4055c304&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4055c304",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("G:\\jenkinsworkspace\\Aigo.OfficialWebsiteAdmin.Publish\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4055c304')) {
      api.createRecord('4055c304', component.options)
    } else {
      api.reload('4055c304', component.options)
    }
    module.hot.accept("./Dashboard.vue?vue&type=template&id=4055c304&scoped=true&", function () {
      api.rerender('4055c304', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/page/Dashboard.vue"
export default component.exports