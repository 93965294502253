<template>
	<div class="filter-layout">
		<el-card class="filter-container" shadow="never">
			<div v-if="arg.dropdown">
				<!-- <el-dropdown class="umar-b10 float-l" @command="handleCommand">
					<el-button type="primary">数据同步<i class="el-icon-arrow-down el-icon--right"></i> </el-button>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item :command="{ tag: 'base_sync', forced: false }">同步产品基础信息</el-dropdown-item>
						<el-dropdown-item :command="{ tag: 'pic_sync', forced: false }">同步图片</el-dropdown-item>
						<el-dropdown-item :command="{ tag: 'detail_sync', forced: false }">同步详情</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
				<el-dropdown class="umar-b10 float-l umar-l10" @command="handleCommand">
					<el-button type="primary">强制同步<i class="el-icon-arrow-down el-icon--right"></i> </el-button>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item :command="{ tag: 'base_sync', forced: true }">同步产品基础信息</el-dropdown-item>
						<el-dropdown-item :command="{ tag: 'pic_sync', forced: true }">同步图片</el-dropdown-item>
						<el-dropdown-item :command="{ tag: 'param_sync', forced: true }">同步参数</el-dropdown-item>
						<el-dropdown-item :command="{ tag: 'detail_sync', forced: true }">同步详情</el-dropdown-item>
						<el-dropdown-item :command="{ tag: 'stock_sync', forced: true }">同步库存</el-dropdown-item>
						<el-dropdown-item :command="{ tag: 'price_sync', forced: true }">同步价格</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown> -->
				<el-button style="margin-left: 10px" type="primary" @click="putClick">批量上架</el-button>
				<el-button style="margin-left: 10px" type="primary" @click="offClick">批量下架</el-button>
				<!-- <el-button class="umar-b10  upload-btn" type="primary"
				    >导入更新
				    <div id="clone_input">
				        <input class="upload-btn-file" type="file" ref="file" @change="importClick" :accept="accept" />
				    </div>
				</el-button> -->
				<!-- <el-button style="margin-left: 10px;" type="primary" @click="importClick">
					导入更新
				</el-button> -->
				<!-- <el-link
				    class="umar-b10 umar-l10"
				    href="https://aigo-oss-mall.oss-eu-central-1.aliyuncs.com/b2b/template/%E4%B8%9A%E5%8A%A1%E5%91%98%E6%A1%A3%E6%A1%88%E5%AF%BC%E5%85%A5%E6%A8%A1%E7%89%88.xlsx"
				    target="_blank"
				    ><span class="down-template">下载导入模板</span></el-link
				> -->
				<!-- <span class="down-template" @click="downloadTemplateClick"><span>下载导入模板</span></span> -->
			</div>

			<div style="margin-top: 15px">
				<el-form :inline="true" :model="listQueryData" size="small" label-width="140px">
					<el-form-item v-if="arg.name">
						<el-input v-model="listQueryData.title" @input="e => listQueryData.title = validForbid(e)" class="input-width" clearable :placeholder="placeholder.name  || 'ID'"></el-input>
					</el-form-item>
					<el-form-item v-if="arg.name2">
						<el-input v-model="listQueryData.title2" class="input-width" :placeholder="placeholder.name2  || '兑换码'"></el-input>
					</el-form-item>
					<el-form-item v-if="arg.name3">
						<el-input v-model="listQueryData.title3" class="input-width" :placeholder="placeholder.name3  || '使用订单号'"></el-input>
					</el-form-item>
					<el-form-item v-if="arg.parameter">
						<el-select v-model="listQueryData.parameter"  class="input-width" placeholder="类型">
							<el-option v-for="item in dictionary.type" :key="item.id" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="arg.parameterName">
						<el-input v-model="listQueryData.parameterName" class="input-width" placeholder="参数名称"></el-input>
					</el-form-item>							
					<el-form-item v-if="arg.type">
						<el-select v-model="listQueryData.type"  class="input-width" placeholder="类型">
							<el-option v-for="item in dictionary.type" :key="item.id" :label="item.name"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="arg.code">
						<el-input v-model="listQueryData.code" class="input-width" placeholder="SKU/EAN/编码"></el-input>
					</el-form-item>
					<el-form-item v-if="arg.put">
						<el-select v-model="listQueryData.put"  class="input-width" placeholder="上架">
							<el-option v-for="item in statusOptions" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="arg.category">
						<el-cascader placeholder="请选择类目" ref="myCategory" v-model="listQueryData.classifyTree" :options="dictionary.classifyTree"
						  :props="{checkStrictly: true,value:'goodsClassifyId',label:'title',children:'children'}" @change="handleChangeCategory" :show-all-levels="false"></el-cascader>
					</el-form-item>
					<el-form-item v-if="arg.country">
						<div v-if="multiple">
							<el-select v-model="listQueryData.country"  multiple class="input-width" :placeholder="placeholder.country || '销售国家'">
								<el-option
									v-for="item in dictionary.country"
									:key="item.id"
									:label="item.name"
									:value="item.code">
								</el-option>      
							</el-select>
						</div>
						<div v-else>
							<el-select v-model="listQueryData.country"  class="input-width" :placeholder="placeholder.country || '销售国家'">
								<el-option
									v-for="item in dictionary.country"
									:key="item.id"
									:label="item.name"
									:value="item.code">
								</el-option>      
							</el-select>
						</div>
					</el-form-item>
					<el-form-item v-if="arg.position">
						<el-select v-model="listQueryData.position"  class="input-width" placeholder="位置">
							<el-option v-for="(item,index) in dictionary.bannerPosition" :key="index" :label="item.name"
								:value="item.code">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="arg.brand">
						<el-select v-model="listQueryData.brand"  class="input-width" placeholder="品牌">
							<el-option v-for="(item,index) in dictionary.brandCode" :key="index" :label="item.name"
								:value="item.code">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="arg.jumpType">
						<el-select v-model="listQueryData.jumpType" class="input-width" :placeholder="placeholder.jumpType || '跳转类型'">
							<el-option v-for="item in dictionary.jump" :key="item.id" :label="item.name"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="arg.fixedCode">
						<el-select v-model="listQueryData.fixedCode"  class="input-width" placeholder="是否有固定码">
							<el-option v-for="item in dictionary.fixedCode" :key="item.id" :label="item.name"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="arg.czr">
						<el-select v-model="listQueryData.czr"  class="input-width" placeholder="操作人">
							<el-option v-for="item in dictionary.czr" :key="item.id" :label="item.name"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="arg.yuqi">
						<el-select v-model="listQueryData.yuqi"  class="input-width" placeholder="状态">
							<el-option v-for="item in dictionary.yuqi" :key="item.id" :label="item.name"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="arg.operator">
						<el-select v-model="listQueryData.operator"  class="input-width" placeholder="操作人" clearable>
							<el-option v-for="item in dictionary.operator" :key="item.user_id" :label="item.legal_name"
								:value="item.user_id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="arg.promotion">
						<el-select v-model="listQueryData.promotion"  class="input-width" :placeholder="placeholder.promotion || '是否促销'">
							<el-option v-for="item in dictionary.promotion" :key="item.id" :label="item.name"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="arg.receiveState">
						<el-select v-model="listQueryData.isReceived"  class="input-width" :placeholder="placeholder.receiveState || '领取状态'">
							<el-option v-for="item in dictionary.isReceived" :key="item.value" :label="item.name"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					
					<el-form-item v-if="arg.state">
						<el-select v-model="listQueryData.state"  class="input-width" :placeholder="placeholder.state || '活动状态'">
							<el-option v-for="item in dictionary.state" :key="item.id" :label="item.name"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>	
					<el-form-item v-if="arg.over">
						<el-select v-model="listQueryData.isOverlayDiscount"  class="input-width" placeholder="是否叠加">
							<el-option v-for="item in dictionary.over" :key="item.id" :label="item.name"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="arg.enable">
						<el-select v-model="listQueryData.enable"  class="input-width" placeholder="是否启用">
							<el-option v-for="item in dictionary.status" :key="item.id" :label="item.name"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="arg.date">
						<el-date-picker v-model="listQueryData.date" type="daterange" range-separator="至"
							:start-placeholder="placeholder.date.start || '开始日期'" :end-placeholder="placeholder.date.end || '结束日期'"
							format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"/>
					</el-form-item>
					<el-form-item v-if="arg.date2">
						<el-date-picker v-model="listQueryData.date2" type="daterange" range-separator="至"
							:start-placeholder="placeholder.date2.start || '开始日期'" :end-placeholder="placeholder.date2.end || '结束日期'"
							format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"/>
					</el-form-item>
					<el-form-item v-if="arg.useState">
						<el-select v-model="listQueryData.isUsed"  class="input-width" :placeholder="placeholder.receiveState || '使用状态'">
							<el-option v-for="item in dictionary.isUsed" :key="item.value" :label="item.name"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button style="float:right" @click="handleResetSearch()" size="small">
							重置
						</el-button>
						<el-button v-if='isShowObject.export'  type="primary" @click="handleExport()" size="small"
						    >{{placeholder.exportText}}</el-button>
						<el-button style="float:right;margin-right: 15px" type="primary" @click="handleSearchList()"
							size="small">
							搜索
						</el-button>					
					</el-form-item>
				</el-form>
			</div>
		</el-card>
	</div>
</template>

<script>
	export default {
		props: {
			defaultListQuery:{
				type: Object,
				default: () => {
					return {}
				}
			},
			dictionary: {
				//字典
				type: Object,
				default: () => {
					return {}
				}
			},
			listQuery: {
				//数据
				type: Object,
				default: () => {
					return {}
				}
			},
			isShowObject:{
				type: Object,
				default: () => {
					return {
						export:false
					}
				}
			},
			accept: {
			    type: Array,
			    default: () => {
			    	return ['.pdf','.doc','.docx','.xls','.xlsx']
			    }		
			},
			importAction: {
			    type: String,
			    default: ''
			},
			multiple:{
				type:Boolean,
				default:true
			},
			arg: {
				//标签是否显示
				type: Object,
				default: () => {
					return {
						name: false,
						name2: false,
						name3: false,
						brand: false,
						czr: false,
						date: false,
						date2: false,
						code: false,
						type:false,
						country: false,
						status: false,
						dropdown: false,
						category:false,
						promotion:false,
						jumpType:false,
						position:false,
						enable:false,
						put:false,
						state:false,
						over:false,
						parameterName:false,
						parameter:false,
						useState:false
					}
				}
			},
			placeholder: {
				//placeholdert替换
				type: Object,
				default: () => {
					return {
						name:"ID",
						name2:"兑换码",
						name3:"使用订单号",
						state:"活动状态",
						date: {
							start: "开始日期",
							end: "结束日期"
						},
						date2: {
							start: "开始日期",
							end: "结束日期"
						},
						exportText:"导入"
					}
				}
			}
		},
		data() {
			return {
				levelList: null,
				listQueryData:this.listQuery
			}
		},
		watch: {},
		methods: {
			handleCommand(val){
				this.$emit('handleCommand',val)
			},
			handleSearchList(){
				this.$emit('handleSearchList',this.listQueryData)
			},
			handleResetSearch(){
				this.listQueryData = Object.assign({}, this.defaultListQuery);
				this.$emit('handleResetSearch')			
			},
			putClick(){
				this.$emit('putClick')
			},
			offClick(){
				this.$emit('offClick')				
			},
			//选择类目
			handleChangeCategory(val){
				console.log('handleChangeCategory',val)
				this.$emit('handleChangeCategory',val)
			},
			//导入
			handleExport(){
				this.$emit('handleExport')
			},
			downloadTemplateClick(){
				this.$emit('downloadTemplateClick')
			},
			importClick(){
				    let file = this.$refs.file.files[0];
				    let ext = this.getSuffix(file.name);
				    if (this.accept.indexOf(ext) < 0) {
				        this.$message.error('仅支持' + this.accept + '格式的文件');
				        return;
				    }
				
				    let formData = new FormData(); //new一个formData事件
				    formData.append('file', this.$refs.file.files[0]); //将file属性添加到formData里
				    this.$store.dispatch(this.importAction, formData).then((res) => {
				        console.log(res);
				        if (res && res.status == 200) {
				            if (res.data.success) {
				                this.messageOption = this.$message.success('导入成功');
				                this.$emit('importSuccess', res.data, this.messageOption);
				            } else this.$message.error(res.data.message);
				        }
				    });
				    this.$refs.file.value = '';
			}
		},
		mounted() {
		},
	}
</script>

<style scoped>
	.filter-layout {}

	.input-width {
		width: 203px;
	}

	.down-template {
		text-decoration: underline;
		color: rgb(38, 166, 222);
		font-weight: 400;
		font-style: normal;
		font-size: 13px;
		line-height: 13px;
		margin: 20px 0px 0px 10px;
		cursor: pointer;
	}
</style>
