var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          ref: "uploadImg",
          staticClass: "avatar-uploader",
          attrs: {
            action: _vm.ossInfo.ossSignature.host,
            "show-file-list": false,
            "auto-upload": false,
            "list-type": "picture-card",
            "on-change": _vm.handleChange,
            data: _vm.fileDataObj,
            accept: _vm.acceptType
          }
        },
        [
          _vm.formListQuery.bannerFiles[_vm.index].imgUrl
            ? _c("el-image", {
                staticClass: "avatar",
                attrs: {
                  src: _vm.formListQuery.bannerFiles[_vm.index].imgUrl,
                  fit: "contain"
                }
              })
            : _c("i", { staticClass: "el-icon-plus avatar-uploader-icon" })
        ],
        1
      ),
      _vm.divShow
        ? _c(
            "div",
            {
              staticStyle: {
                "margin-left": "20px",
                display: "flex",
                "justify-content": "flex-start",
                "align-items": "flex-end"
              }
            },
            [_c("span", [_vm._v("建议尺寸：5*1024，≤5MB")])]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }