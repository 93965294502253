var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("filter-container", {
        attrs: {
          arg: _vm.arg,
          defaultListQuery: _vm.defaultListQuery,
          placeholder: _vm.placeholder,
          dictionary: _vm.dictionary
        },
        on: {
          handleSearchList: _vm.handleSearchList,
          handleResetSearch: _vm.handleResetSearch
        }
      }),
      _c(
        "div",
        { staticStyle: { margin: "20px 0px 20px 20px" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "success", size: "small" },
              on: { click: _vm.add }
            },
            [_vm._v("添加")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "pagedata",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.pageData.list,
            border: "",
            "header-cell-style": { background: "#EAEEF9", color: "#32353a" }
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "bannerId",
              label: "编号",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "bannerPosition",
              label: "位置",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("dataText", {
                      staticClass: "filter-item",
                      attrs: { clearable: "", codeType: "banner_position" },
                      model: {
                        value: scope.row.bannerPosition,
                        callback: function($$v) {
                          _vm.$set(scope.row, "bannerPosition", $$v)
                        },
                        expression: "scope.row.bannerPosition"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "bannerType1",
              label: "类型",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "brandCode",
              label: "品牌",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("dataText", {
                      attrs: { codeType: "brand" },
                      model: {
                        value: scope.row.brandCode,
                        callback: function($$v) {
                          _vm.$set(scope.row, "brandCode", $$v)
                        },
                        expression: "scope.row.brandCode"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "bannerCountries",
              label: "国家",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.bannerCountries, function(item) {
                    return _c(
                      "span",
                      { key: item.bannerCountryId },
                      [
                        _c("dataText", {
                          attrs: { codeType: "country_ot" },
                          model: {
                            value: item.countryCode,
                            callback: function($$v) {
                              _vm.$set(item, "countryCode", $$v)
                            },
                            expression: "item.countryCode"
                          }
                        })
                      ],
                      1
                    )
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "title",
              label: "名称",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "bannerFiles",
              label: "图片/视频",
              align: "center",
              width: "130px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.bannerType === "Picture" &&
                    scope.row.imgUrl1 &&
                    scope.row.imgUrl1.imgUrl
                      ? _c("el-image", {
                          staticStyle: { width: "100px", height: "100px" },
                          attrs: { src: scope.row.imgUrl1.imgUrl, fit: "fill" }
                        })
                      : scope.row.bannerType === "Video" &&
                        scope.row.videoUrl1 &&
                        scope.row.videoUrl1.videoUrl
                      ? _c(
                          "video",
                          {
                            staticClass: "float-l",
                            staticStyle: { width: "100px", height: "100px" },
                            attrs: {
                              src: scope.row.videoUrl1.videoUrl,
                              controls: "controls"
                            }
                          },
                          [
                            _vm._v(
                              "\n                    您的浏览器不支持视频播放\n                "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "bannerJumpType",
              label: "跳转类型",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.bannerJumpType == "Link"
                            ? "有跳转"
                            : "无跳转"
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "targetUrl",
              label: "跳转地址",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.jumpUrl1 && scope.row.jumpUrl1.jumpUrl
                      ? _c("span", [_vm._v(_vm._s(scope.row.jumpUrl1.jumpUrl))])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "status1",
              label: "状态",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("dataText", {
                      staticClass: "filter-item",
                      attrs: {
                        color: scope.row.statusColor,
                        clearable: "",
                        codeType: "activity_status"
                      },
                      model: {
                        value: scope.row.status1,
                        callback: function($$v) {
                          _vm.$set(scope.row, "status1", $$v)
                        },
                        expression: "scope.row.status1"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "title", label: "是否启用", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      attrs: { "active-color": "#13ce66", disabled: "" },
                      model: {
                        value: scope.row.status,
                        callback: function($$v) {
                          _vm.$set(scope.row, "status", $$v)
                        },
                        expression: "scope.row.status"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "beginTime",
              label: "开始时间",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatDate")(scope.row.beginTime)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "endTime",
              label: "结束时间",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatDate")(scope.row.endTime)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "sort",
              "render-header": _vm.renderSortHeader,
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-input", {
                      attrs: {
                        oninput: "value=value.replace(/^\\.+|[^\\d]/g,'')",
                        placeholder: "请输入内容"
                      },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.putSortBanner(scope.row)
                        }
                      },
                      model: {
                        value: scope.row.sort,
                        callback: function($$v) {
                          _vm.$set(scope.row, "sort", $$v)
                        },
                        expression: "scope.row.sort"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "updateTime", label: "更新时间", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatDate")(row.updateTime)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "updateByName", label: "操作人", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.edit(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.handleDelete(scope.row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.listQuery.page,
              "page-size": _vm.listQuery.limit,
              layout: "total, prev, pager, next, jumper",
              total: _vm.pageData.total
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.isEdit ? "编辑banner" : "添加banner",
            visible: _vm.dialogFormVisible,
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _vm.dialogFormVisible
            ? _c(
                "div",
                [
                  _c("varoant-dialog", {
                    attrs: {
                      isEdit: _vm.isEdit,
                      formListQuery: _vm.formListQuery,
                      bannerId: _vm.bannerId,
                      dictionary: _vm.dictionary
                    },
                    on: {
                      closeDialog: _vm.closeDialog,
                      handleSearchList: _vm.handleSearchList
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }