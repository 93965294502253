var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "filter-layout" },
    [
      _c(
        "el-card",
        { staticClass: "filter-container", attrs: { shadow: "never" } },
        [
          _vm.arg.dropdown
            ? _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.putClick }
                    },
                    [_vm._v("批量上架")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.offClick }
                    },
                    [_vm._v("批量下架")]
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticStyle: { "margin-top": "15px" } },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    inline: true,
                    model: _vm.listQueryData,
                    size: "small",
                    "label-width": "140px"
                  }
                },
                [
                  _vm.arg.name
                    ? _c(
                        "el-form-item",
                        [
                          _c("el-input", {
                            staticClass: "input-width",
                            attrs: {
                              clearable: "",
                              placeholder: _vm.placeholder.name || "ID"
                            },
                            on: {
                              input: function(e) {
                                return (_vm.listQueryData.title = _vm.validForbid(
                                  e
                                ))
                              }
                            },
                            model: {
                              value: _vm.listQueryData.title,
                              callback: function($$v) {
                                _vm.$set(_vm.listQueryData, "title", $$v)
                              },
                              expression: "listQueryData.title"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.arg.name2
                    ? _c(
                        "el-form-item",
                        [
                          _c("el-input", {
                            staticClass: "input-width",
                            attrs: {
                              placeholder: _vm.placeholder.name2 || "兑换码"
                            },
                            model: {
                              value: _vm.listQueryData.title2,
                              callback: function($$v) {
                                _vm.$set(_vm.listQueryData, "title2", $$v)
                              },
                              expression: "listQueryData.title2"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.arg.name3
                    ? _c(
                        "el-form-item",
                        [
                          _c("el-input", {
                            staticClass: "input-width",
                            attrs: {
                              placeholder: _vm.placeholder.name3 || "使用订单号"
                            },
                            model: {
                              value: _vm.listQueryData.title3,
                              callback: function($$v) {
                                _vm.$set(_vm.listQueryData, "title3", $$v)
                              },
                              expression: "listQueryData.title3"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.arg.parameter
                    ? _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "input-width",
                              attrs: { placeholder: "类型" },
                              model: {
                                value: _vm.listQueryData.parameter,
                                callback: function($$v) {
                                  _vm.$set(_vm.listQueryData, "parameter", $$v)
                                },
                                expression: "listQueryData.parameter"
                              }
                            },
                            _vm._l(_vm.dictionary.type, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.arg.parameterName
                    ? _c(
                        "el-form-item",
                        [
                          _c("el-input", {
                            staticClass: "input-width",
                            attrs: { placeholder: "参数名称" },
                            model: {
                              value: _vm.listQueryData.parameterName,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.listQueryData,
                                  "parameterName",
                                  $$v
                                )
                              },
                              expression: "listQueryData.parameterName"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.arg.type
                    ? _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "input-width",
                              attrs: { placeholder: "类型" },
                              model: {
                                value: _vm.listQueryData.type,
                                callback: function($$v) {
                                  _vm.$set(_vm.listQueryData, "type", $$v)
                                },
                                expression: "listQueryData.type"
                              }
                            },
                            _vm._l(_vm.dictionary.type, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.arg.code
                    ? _c(
                        "el-form-item",
                        [
                          _c("el-input", {
                            staticClass: "input-width",
                            attrs: { placeholder: "SKU/EAN/编码" },
                            model: {
                              value: _vm.listQueryData.code,
                              callback: function($$v) {
                                _vm.$set(_vm.listQueryData, "code", $$v)
                              },
                              expression: "listQueryData.code"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.arg.put
                    ? _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "input-width",
                              attrs: { placeholder: "上架" },
                              model: {
                                value: _vm.listQueryData.put,
                                callback: function($$v) {
                                  _vm.$set(_vm.listQueryData, "put", $$v)
                                },
                                expression: "listQueryData.put"
                              }
                            },
                            _vm._l(_vm.statusOptions, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.arg.category
                    ? _c(
                        "el-form-item",
                        [
                          _c("el-cascader", {
                            ref: "myCategory",
                            attrs: {
                              placeholder: "请选择类目",
                              options: _vm.dictionary.classifyTree,
                              props: {
                                checkStrictly: true,
                                value: "goodsClassifyId",
                                label: "title",
                                children: "children"
                              },
                              "show-all-levels": false
                            },
                            on: { change: _vm.handleChangeCategory },
                            model: {
                              value: _vm.listQueryData.classifyTree,
                              callback: function($$v) {
                                _vm.$set(_vm.listQueryData, "classifyTree", $$v)
                              },
                              expression: "listQueryData.classifyTree"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.arg.country
                    ? _c("el-form-item", [
                        _vm.multiple
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "input-width",
                                    attrs: {
                                      multiple: "",
                                      placeholder:
                                        _vm.placeholder.country || "销售国家"
                                    },
                                    model: {
                                      value: _vm.listQueryData.country,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.listQueryData,
                                          "country",
                                          $$v
                                        )
                                      },
                                      expression: "listQueryData.country"
                                    }
                                  },
                                  _vm._l(_vm.dictionary.country, function(
                                    item
                                  ) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.code
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "input-width",
                                    attrs: {
                                      placeholder:
                                        _vm.placeholder.country || "销售国家"
                                    },
                                    model: {
                                      value: _vm.listQueryData.country,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.listQueryData,
                                          "country",
                                          $$v
                                        )
                                      },
                                      expression: "listQueryData.country"
                                    }
                                  },
                                  _vm._l(_vm.dictionary.country, function(
                                    item
                                  ) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.code
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                      ])
                    : _vm._e(),
                  _vm.arg.position
                    ? _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "input-width",
                              attrs: { placeholder: "位置" },
                              model: {
                                value: _vm.listQueryData.position,
                                callback: function($$v) {
                                  _vm.$set(_vm.listQueryData, "position", $$v)
                                },
                                expression: "listQueryData.position"
                              }
                            },
                            _vm._l(_vm.dictionary.bannerPosition, function(
                              item,
                              index
                            ) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.code }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.arg.brand
                    ? _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "input-width",
                              attrs: { placeholder: "品牌" },
                              model: {
                                value: _vm.listQueryData.brand,
                                callback: function($$v) {
                                  _vm.$set(_vm.listQueryData, "brand", $$v)
                                },
                                expression: "listQueryData.brand"
                              }
                            },
                            _vm._l(_vm.dictionary.brandCode, function(
                              item,
                              index
                            ) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.code }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.arg.jumpType
                    ? _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "input-width",
                              attrs: {
                                placeholder:
                                  _vm.placeholder.jumpType || "跳转类型"
                              },
                              model: {
                                value: _vm.listQueryData.jumpType,
                                callback: function($$v) {
                                  _vm.$set(_vm.listQueryData, "jumpType", $$v)
                                },
                                expression: "listQueryData.jumpType"
                              }
                            },
                            _vm._l(_vm.dictionary.jump, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.arg.fixedCode
                    ? _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "input-width",
                              attrs: { placeholder: "是否有固定码" },
                              model: {
                                value: _vm.listQueryData.fixedCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.listQueryData, "fixedCode", $$v)
                                },
                                expression: "listQueryData.fixedCode"
                              }
                            },
                            _vm._l(_vm.dictionary.fixedCode, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.arg.czr
                    ? _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "input-width",
                              attrs: { placeholder: "操作人" },
                              model: {
                                value: _vm.listQueryData.czr,
                                callback: function($$v) {
                                  _vm.$set(_vm.listQueryData, "czr", $$v)
                                },
                                expression: "listQueryData.czr"
                              }
                            },
                            _vm._l(_vm.dictionary.czr, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.arg.yuqi
                    ? _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "input-width",
                              attrs: { placeholder: "状态" },
                              model: {
                                value: _vm.listQueryData.yuqi,
                                callback: function($$v) {
                                  _vm.$set(_vm.listQueryData, "yuqi", $$v)
                                },
                                expression: "listQueryData.yuqi"
                              }
                            },
                            _vm._l(_vm.dictionary.yuqi, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.arg.operator
                    ? _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "input-width",
                              attrs: { placeholder: "操作人", clearable: "" },
                              model: {
                                value: _vm.listQueryData.operator,
                                callback: function($$v) {
                                  _vm.$set(_vm.listQueryData, "operator", $$v)
                                },
                                expression: "listQueryData.operator"
                              }
                            },
                            _vm._l(_vm.dictionary.operator, function(item) {
                              return _c("el-option", {
                                key: item.user_id,
                                attrs: {
                                  label: item.legal_name,
                                  value: item.user_id
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.arg.promotion
                    ? _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "input-width",
                              attrs: {
                                placeholder:
                                  _vm.placeholder.promotion || "是否促销"
                              },
                              model: {
                                value: _vm.listQueryData.promotion,
                                callback: function($$v) {
                                  _vm.$set(_vm.listQueryData, "promotion", $$v)
                                },
                                expression: "listQueryData.promotion"
                              }
                            },
                            _vm._l(_vm.dictionary.promotion, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.arg.receiveState
                    ? _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "input-width",
                              attrs: {
                                placeholder:
                                  _vm.placeholder.receiveState || "领取状态"
                              },
                              model: {
                                value: _vm.listQueryData.isReceived,
                                callback: function($$v) {
                                  _vm.$set(_vm.listQueryData, "isReceived", $$v)
                                },
                                expression: "listQueryData.isReceived"
                              }
                            },
                            _vm._l(_vm.dictionary.isReceived, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.name, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.arg.state
                    ? _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "input-width",
                              attrs: {
                                placeholder: _vm.placeholder.state || "活动状态"
                              },
                              model: {
                                value: _vm.listQueryData.state,
                                callback: function($$v) {
                                  _vm.$set(_vm.listQueryData, "state", $$v)
                                },
                                expression: "listQueryData.state"
                              }
                            },
                            _vm._l(_vm.dictionary.state, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.arg.over
                    ? _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "input-width",
                              attrs: { placeholder: "是否叠加" },
                              model: {
                                value: _vm.listQueryData.isOverlayDiscount,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.listQueryData,
                                    "isOverlayDiscount",
                                    $$v
                                  )
                                },
                                expression: "listQueryData.isOverlayDiscount"
                              }
                            },
                            _vm._l(_vm.dictionary.over, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.arg.enable
                    ? _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "input-width",
                              attrs: { placeholder: "是否启用" },
                              model: {
                                value: _vm.listQueryData.enable,
                                callback: function($$v) {
                                  _vm.$set(_vm.listQueryData, "enable", $$v)
                                },
                                expression: "listQueryData.enable"
                              }
                            },
                            _vm._l(_vm.dictionary.status, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.arg.date
                    ? _c(
                        "el-form-item",
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "daterange",
                              "range-separator": "至",
                              "start-placeholder":
                                _vm.placeholder.date.start || "开始日期",
                              "end-placeholder":
                                _vm.placeholder.date.end || "结束日期",
                              format: "yyyy-MM-dd",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              "default-time": ["00:00:00", "23:59:59"]
                            },
                            model: {
                              value: _vm.listQueryData.date,
                              callback: function($$v) {
                                _vm.$set(_vm.listQueryData, "date", $$v)
                              },
                              expression: "listQueryData.date"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.arg.date2
                    ? _c(
                        "el-form-item",
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "daterange",
                              "range-separator": "至",
                              "start-placeholder":
                                _vm.placeholder.date2.start || "开始日期",
                              "end-placeholder":
                                _vm.placeholder.date2.end || "结束日期",
                              format: "yyyy-MM-dd",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              "default-time": ["00:00:00", "23:59:59"]
                            },
                            model: {
                              value: _vm.listQueryData.date2,
                              callback: function($$v) {
                                _vm.$set(_vm.listQueryData, "date2", $$v)
                              },
                              expression: "listQueryData.date2"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.arg.useState
                    ? _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "input-width",
                              attrs: {
                                placeholder:
                                  _vm.placeholder.receiveState || "使用状态"
                              },
                              model: {
                                value: _vm.listQueryData.isUsed,
                                callback: function($$v) {
                                  _vm.$set(_vm.listQueryData, "isUsed", $$v)
                                },
                                expression: "listQueryData.isUsed"
                              }
                            },
                            _vm._l(_vm.dictionary.isUsed, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.name, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { float: "right" },
                          attrs: { size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.handleResetSearch()
                            }
                          }
                        },
                        [_vm._v("\n\t\t\t\t\t\t重置\n\t\t\t\t\t")]
                      ),
                      _vm.isShowObject.export
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.handleExport()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.placeholder.exportText))]
                          )
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            float: "right",
                            "margin-right": "15px"
                          },
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.handleSearchList()
                            }
                          }
                        },
                        [_vm._v("\n\t\t\t\t\t\t搜索\n\t\t\t\t\t")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }